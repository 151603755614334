import styled, { css } from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.typography.family};
    color: ${theme.supportColors.dark50};
    font-size: ${theme.fontSize.sm};
    line-height: ${theme.fontSize.xl};
    font-weight: 700;
  `}
`;

export const SeeMore = styled.a`
  ${({ theme }) => css`
    font-family: ${theme.typography.family};
    color: ${theme.mainColors.primary.base};
    font-size: ${theme.fontSize.sm};
    font-weight: 700;
  `}
`;

export const ProductContainer = styled.div`
  margin-bottom: 8px;
  .card {
    height: 100%;
  }
`;
