import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $isSmallFont?: boolean }>`
  span {
    ${({ theme, $isSmallFont }) => css`
      font-family: ${theme.typography.family};
      font-size: ${theme.fontSize.xxs};
      font-weight: 500;
      line-height: 17px;
      color: ${theme.complementaryColors.blue.dark10};
      background-color: ${theme.complementaryColors.blue.light50};
      padding: 0 8px;
      border-radius: 100px;
      display: table;
      ${() =>
        !$isSmallFont &&
        css`
          @media (min-width: 1024px) {
            font-size: ${theme.fontSize.xs};
            line-height: 19px;
          }
        `}
    `}
  }
`;
