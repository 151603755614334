import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'seamless-immutable';
import { Observer } from '@catalogo/theme-observer';
import { useLazyQuery } from '@apollo/client';
import { withMetrics } from '@catalogo/core-metrics';
import { withOneDayDelivery } from '@catalogo/core-connect/one-day-delivery';
import compose from 'lodash/fp/compose';
import { sendBeacon } from '@catalogo/core-send-beacon';
import { getIsPrime, oneDayDeliveryMsg } from './helpers/product';

const hasOffers = value => !!getIn(value, ['offers', 'result'], []).length;

const withProductHOC = WrappedComponent => {
  const Component = ({ dispatchMetrics, ...props }) => {
    const { id, sellerId } = props;
    const onCompleted = data => {
      const { product } = data;
      if (!hasOffers(product)) {
        dispatchMetrics('outOfStock:load', { props, product });
      }
    };
    const [fetchProduct, { loading, error, called, refetch, data }] = useLazyQuery(props.query, { onCompleted });
    const product = getIn(data, ['product'], {});

    const onceVisible = () => {
      fetchProduct({ variables: { productId: id, sellerId, promoted: true } });
    };

    const onClickProduct = () => {
      const { position, index, trackingUrl } = props;

      const { offers } = props.product || product;

      const prime = getIsPrime(props.product || product);
      const bestPaymentOption = getIn(offers, ['result', 0, 'bestPaymentOption']);
      const salesPrice =
        getIn(bestPaymentOption, ['minInstallment', 0, 'total']) || getIn(bestPaymentOption, ['price']);
      const oneDayDelivery = oneDayDeliveryMsg(props.product || product);
      const offerId = getIn(offers, ['result', 0, 'offerId'], '');

      if (trackingUrl) {
        sendBeacon(trackingUrl);
      }

      dispatchMetrics('product:click', { position, id, index, salesPrice, prime, oneDayDelivery, offerId });
    };

    if (props.product) {
      return (
        <div onClick={onClickProduct} className="card">
          <WrappedComponent loading={loading} called={called} error={error} {...props} product={props.product} />
        </div>
      );
    }

    if (error) {
      error.retry = () => refetch({ variables: { productId: id, sellerId, promoted: true } });
    }

    return (
      <Observer onceVisible={() => onceVisible()}>
        <div onClick={onClickProduct} className="card">
          <WrappedComponent loading={loading} called={called} error={error} product={product} {...props} />
        </div>
      </Observer>
    );
  };

  Component.propTypes = {
    id: PropTypes.string,
    sellerId: PropTypes.string,
    product: PropTypes.object,
    query: PropTypes.object,
  };

  return Component;
};

export const withProduct = compose(withMetrics, withOneDayDelivery, withProductHOC);
