import styled, { css } from 'styled-components';

export const InstallmentText = styled.span`
  ${({ theme }) => css`
    color: ${theme.grey.dark};
    font-size: ${theme.fontSize.xxs};
    height: 42px;

    @media (min-width: 1024px) {
      height: 28px;
    }
  `}
`;
