import React from 'react';
import styled from 'styled-components';
import withBannerVitrineAds from '@catalogo/service-americanas-spacey/src/hocs/banner-vitrine-ads';
import B2wAds from '@catalogo/ui-americanas-mobile-b2wads-google';
import { withMetrics } from '@catalogo/core-metrics';
import compose from 'lodash/fp/compose';
import Title from './components/title';
import Carousel from './components/carousel';
import Disclaimer from './components/disclaimer';
import { BannerVitrineAdsProps } from './helper/interfaces';

export const BannerVitrineAds: React.FC<BannerVitrineAdsProps> = props => {
  const {
    componentTitle,
    linkSeeAll,
    b2wAdsBanner,
    backgroundColor,
    products,
    publication,
    productCardQuery,
    disclaimerColor,
    isMobile,
  } = props;

  return (
    <Container>
      <Title componentTitle={componentTitle} linkSeeAll={linkSeeAll} />
      <Component backgroundColor={backgroundColor}>
        <B2wAds publication={b2wAdsBanner} />
        <Carousel
          products={products}
          publication={publication}
          productCardQuery={productCardQuery}
          isMobile={isMobile}
        />
        <Disclaimer disclaimerColor={disclaimerColor} />
      </Component>
    </Container>
  );
};

const Container = styled.div`
  margin: 22px 8px 0px;
`;

const Component = styled.div<{ backgroundColor: string }>`
  display: flex;
  overflow: hidden;
  min-height: 400px;
  border-radius: 29px;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export default compose(withMetrics, withBannerVitrineAds)(BannerVitrineAds);
