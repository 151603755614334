import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 16px 0;
  color: ${({ theme }) => theme.grey.dark};
  padding: 0 16px;
  white-space: normal;
  border-radius: 4px;

  @media (min-width: 1024px) {
    margin: 13px 0 0 0;
    padding: 33px 16px;
  }
`;

export const Text = styled.span`
  font-size: 14px;
`;
