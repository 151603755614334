import React from 'react';
import styled, { css } from 'styled-components';

export const Spinner = ({ className = '', size = 'medium', color = '#acacac' }) => (
  <Container className={className} size={size} color={color}>
    <Wrapper size={size}>
      <Bar size={size} />
      <RoundedEdge size={size} />
    </Wrapper>
  </Container>
);

export default Spinner;

const Size = {
  small: css`
    width: 16px;
    height: 16px;
    border-width: 2px;
    &:before {
      width: 2px;
      height: 2px;
    }
    &:after {
      width: 2px;
      height: 2px;
    }
  `,
  medium: css`
    width: 32px;
    height: 32px;
    border-width: 3px;
    &:before {
      width: 3px;
      height: 3px;
    }
    &:after {
      width: 3px;
      height: 3px;
    }
  `,
  large: css`
    width: 56px;
    height: 56px;
    border-width: 4px;
    &:before {
      width: 4px;
      height: 4px;
    }
    &:after {
      width: 4px;
      height: 4px;
    }
  `,
};

const Container = styled.div`
  ${({ size }) => css`
    ${size && Size[size]};
  `}
  
  --color: ${({ color }) => color};
`;

const Wrapper = styled.div`
  ${({ size }) => css`
    display: inline-block;
    position: relative;
    ${size && Size[size]};
    border: 0;
  `}
`;

const RoundedEdge = styled.div`
  ${({ size }) => css`
    ${size && Size[size]}
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    transform: rotate(45deg);
    animation: dotte 0.8s linear infinite;
    animation-delay: -0.45s;
    @keyframes dotte {
      0% {
        transform: rotate(45deg);
      }
      100% {
        transform: rotate(405deg);
      }
    }
    &:before {
      content: '';
      background-color: var(--color);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &:after {
      content: '';
      background-color: var(--color);
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  `}
`;

const Bar = styled.div`
  ${({ size }) => css`
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-radius: 50%;
    border-color: var(--color) transparent transparent transparent;
    border-style: solid;
    animation: spinner 0.8s linear infinite;
    ${size && Size[size]};
  `}

  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
