import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import withProductCard from '@catalogo/service-americanas-product-card/src/hocs/product-card';
import { withProduct } from '@catalogo/core-connect/product';
import { Placeholder } from './placeholder';
import InStockCard from './components/organisms/inStockCard';
import OutOfStockCard from './components/organisms/outOfStockCard';

export const RecProductCard = ({ outOfStock, className, isLoading, ...cardProps }) => {
  if (isLoading) {
    return <Placeholder />;
  }

  return (
    <Wrapper className={className}>
      {!outOfStock && <InStockCard {...cardProps} />}
      {outOfStock && <OutOfStockCard {...cardProps} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  width: 160px;
  height: 100%;
`;

export default compose(withProduct, withProductCard)(RecProductCard);
