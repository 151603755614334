import styled, { css } from 'styled-components';
import { Text } from '@catalogo/theme-text';

export const Price = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.grey.dark};
    font-size: ${theme.fontSize.xxs};
    text-decoration: line-through;
    font-weight: 400;
  `}
`;
