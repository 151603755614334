import styled, { css } from 'styled-components';
import MercadoIcon from '@catalogo/ui-americanas-desktop-svg/mercado.svg';

export const Badge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px 8px;
  height: 28px;
  ${({ theme }) => css`
    background-color: ${theme.grey.lighter};
  `}
`;

export const Icon = styled(MercadoIcon)`
  width: 16px;
  height: 16px;
`;

export const Text = styled.span`
  ${({ withoutIcon }) =>
    !withoutIcon &&
    css`
      padding-left: 5px;
    `};

  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: ${({ theme }) => theme.fontSize.xxs};
  `}
`;
