import React from 'react';
import { getIn } from 'seamless-immutable';
import * as Styled from './styles';

export const FreightTag = ({ stamps }) => {
  const isFreeFreight = getIn(stamps, ['isFreeFreight'], false);

  return (
    <>
      {!!isFreeFreight && (
        <Styled.Badge className="freight-badge">
          <Styled.Text className="freight-badge-text">frete grátis</Styled.Text>
        </Styled.Badge>
      )}
    </>
  );
};

export default FreightTag;
