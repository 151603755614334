import React from 'react';
import { getIn } from 'seamless-immutable';
import * as Styled from './styles';

export const UsedBadge = ({ stamps }) => {
  const isUsed = getIn(stamps, ['isUsed'], false);

  return (
    <>
      {!!isUsed && (
        <Styled.Badge className="used-badge">
          <Styled.Text className="used-badge-text">usado</Styled.Text>
        </Styled.Badge>
      )}
    </>
  );
};

export default UsedBadge;
