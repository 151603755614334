import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { ImageInfo, PriceInfo, ProductInfo } from '@catalogo/theme-product-card';

import BadgesInfo from '../molecules/badges-info';
import ListStamps from '../molecules/list-stamps';

const InStockCard = ({ url, handleClick, ...cardProps }) => (
  <Wrapper>
    <Link to={url} onClick={handleClick}>
      <ImageInfo {...cardProps} />
      <Container>
        <ProductInfo {...cardProps} />
        <PriceInfo {...cardProps} />
        <ListStampsUI {...cardProps} />
      </Container>
      <BadgesInfo {...cardProps} />
    </Link>
  </Wrapper>
);

const Wrapper = styled.div`
  height: 100%;
`;

export default InStockCard;

const Container = styled.div`
  padding: 16px;
`;

const Link = styled(Nav)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ListStampsUI = styled(ListStamps)`
  margin-top: 8px;
`;
