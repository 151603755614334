import styled, { css } from 'styled-components';

export const Badge = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.systemColors.success.light50};
    border-radius: 100px;
    padding: 0 8px;
  `}
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.systemColors.success.dark10};
    font-size: ${({ theme }) => theme.fontSize.xxs};
    font-family: ${theme.typography.family};
    font-weight: 500;
    line-height: 17px;
    text-transform: ${theme.titleTransform};
  `}
`;
