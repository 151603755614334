import React from 'react';
import * as Styled from './styles';

export const RewrappedBadge = ({ stamps, ...props }) => {
  const condition = props?.product?.product?.offers?.result?.[0]?.condition;
  const isRewrapped = condition?.toLowerCase() === 'rewrapped';

  return (
    <>
      {!!isRewrapped && (
        <Styled.Badge className="rewrapped-badge">
          <Styled.Text className="rewrapped-badge-text">reembalado</Styled.Text>
        </Styled.Badge>
      )}
    </>
  );
};

export default RewrappedBadge;
