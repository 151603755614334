import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  gap: 8px;

  @media (min-width: 1024px) {
    gap: 16px 8px;
    margin-bottom: 16px;
    padding: 0 16px;
  }
`;
