import React from 'react';
import { DiscountBadge } from '@catalogo/theme-badges';
import { ListPrice, SalesPrice, Installment, CashbackMessage } from '@catalogo/theme-product-card';
import { ICashbackMessageProps } from '@catalogo/theme-product-card/src/components/atoms/cashback-message/types';
import { IPriceInfoProps } from '../types';
import * as Styled from './styles';

export const PriceInfoDesktop: React.FC<IPriceInfoProps> = props => {
  const { price, isNurslingProduct, showCashbackMessage, cashbackProps, className = '' } = props;
  const { cashback, isSuperCashback } = (cashbackProps ?? {}) as ICashbackMessageProps;

  return (
    <Styled.Wrapper className={className}>
      {!isNurslingProduct && (
        <Styled.Container className="price-info-container">
          <SalesPrice price={price?.price} />
          <Styled.WrapperDiscount className="price-info-wrapper-discount">
            <DiscountBadge discount={price?.discount} />
          </Styled.WrapperDiscount>
        </Styled.Container>
      )}
      <ListPrice promotionalPrice={price?.promotionalPrice} />
      <Installment installmentPhase={price?.installmentPhase} />
      {showCashbackMessage && <CashbackMessage cashback={cashback} isSuperCashback={isSuperCashback} />}
    </Styled.Wrapper>
  );
};

export default PriceInfoDesktop;
