import React from 'react';
import { getIn } from 'seamless-immutable';
import * as Styled from './styles';

export const FastDeliveryTag = ({ stamps, oneDayDeliveryMessage }) => {
  const isFastDelivery = getIn(stamps, ['isFastDelivery'], false);

  return (
    <>
      {!!isFastDelivery && (
        <Styled.Badge className="fast-delivery-badge">
          <Styled.Text className="fast-delivery-badge-text">{oneDayDeliveryMessage}</Styled.Text>
        </Styled.Badge>
      )}
    </>
  );
};

export default FastDeliveryTag;
