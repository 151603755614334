import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@catalogo/theme-skeleton';

export const Placeholder = () => (
  <Wrapper>
    <Skeleton width="232px" height="430px" spinner border="16px" />
  </Wrapper>
);

const Wrapper = styled.div``;

export default Placeholder;
