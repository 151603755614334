import React from 'react';
import { getIn } from 'seamless-immutable';
import * as Styled from './styles';

export const AdsBadge = ({ stamps }) => {
  const isAd = getIn(stamps, ['isAd'], false);

  return (
    <>
      {!!isAd && (
        <Styled.Badge className="ads-badge">
          <Styled.Text className="ads-badge-text">patrocinado</Styled.Text>
        </Styled.Badge>
      )}
    </>
  );
};

export default AdsBadge;
