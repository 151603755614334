import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Carousel as TemplateCarousel } from '@catalogo/theme-carousel';
import RecProductCard from '@catalogo/ui-americanas-mobile-rec-product-card';
import recProductCardQuery from '@catalogo/service-americanas-product-card/src/queries/rec-product-card.graphql';
import Placeholder from '@catalogo/theme-placeholder';

class Carousel extends React.Component {
  render() {
    const { publication, placeholderForced, position, titleStyles } = this.props;

    if (!publication) {
      if (placeholderForced) {
        return <Placeholder count={2} heightTitle="51px" />;
      }
      return null;
    }

    return (
      <Container>
        <Title style={titleStyles}>{publication.title}</Title>
        <TemplateCarousel gap={15}>
          {publication.products.map(({ id, queryString, trackingUrl, sellerId, isAd }) => (
            <ProductContainer key={id}>
              <RecProductCard
                position={position}
                id={id}
                sellerId={sellerId}
                publication={publication}
                queryString={queryString}
                trackingUrl={trackingUrl}
                query={recProductCardQuery}
                isRec={true}
                isAd={isAd}
              />
            </ProductContainer>
          ))}
        </TemplateCarousel>
      </Container>
    );
  }
}

export { Carousel };

Carousel.propTypes = {
  publication: PropTypes.object,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
`;

const Title = styled.h2`
  text-transform: lowercase;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.fontColor || '#666'};
  padding: 20px 0px 10px 10px;
`;

const ProductContainer = styled.div`
  margin-bottom: 8px;
  .card {
    height: 100%;
  }
`;

export default Carousel;
