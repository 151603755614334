import React from 'react';
import { getIn } from 'seamless-immutable';
import * as Styled from './styles';

export const CashbackBadge = ({ stamps }) => {
  const isSuperCashback = getIn(stamps, ['isSuperCashback'], false);

  return (
    <>
      {!!isSuperCashback && (
        <Styled.Badge className="cashback-badge">
          <Styled.Text className="cashback-badge-text">super cashback</Styled.Text>
        </Styled.Badge>
      )}
    </>
  );
};

export default CashbackBadge;
