import React from 'react';
import styled from 'styled-components';
import { AdsBadge, UsedBadge } from '@catalogo/theme-badges';

const BottomBadges = ({ ...cardProps }) => (
  <Container>
    <AdsBadge {...cardProps} />
    <UsedBadge {...cardProps} />
  </Container>
);

const Container = styled.div`
  display: grid;
  gap: 2px;
  margin-top: auto;

  &:empty {
    display: none;
  }
`;

export default BottomBadges;
