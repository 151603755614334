import styled from 'styled-components';
import ArrowDown from '@catalogo/ui-americanas-desktop-svg/arrow_down.svg';

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.card.green};
  border-radius: 16px;
  height: 16px;
  padding: 3px;
`;

export const ArrowDiscount = styled(ArrowDown)`
  width: 8px;
  height: 8px;
`;

export const Text = styled.span`
  margin-left: 4px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  font-size: 10px;
`;
