import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  background: #fafafa;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 16px;
    aspect-ratio: 1 / 1;
  }

  @media (min-width: 1024px) {
    margin-bottom: 16px;
  }
`;

export const WrapperStamp = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 2;

  @media (min-width: 1024px) {
    left: 24px;
    top: 24px;
  }
`;
