import React from 'react';
import styled from 'styled-components';
import { withFastDeliveryExperiment } from '@catalogo/service-americanas-search/src/experiments/fast-delivery-experiment';
import { DiscountProgressiveBadge } from '@catalogo/ui-americanas-mobile-badges';
import { FastDeliveryTag, FreightTag, CashbackBadge } from '@catalogo/theme-badges';

export const BadgesBottomInfo = props => (
  <Wrapper>
    <FreightTag {...props} />
    <FastDeliveryTag {...props} />
    <DiscountProgressiveBadge discountProgressiveStamp={props?.stamps?.discountProgressiveStamp} isSmallFont />
    <CashbackBadge {...props} />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding-left: 16px;
  margin-bottom: 16px;

  &:empty {
    display: none;
  }
`;

export default withFastDeliveryExperiment(BadgesBottomInfo);
