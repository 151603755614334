import styled, { css } from 'styled-components';
import IconAirplane from '@catalogo/ui-americanas-desktop-svg/airplane.svg';

export const Badge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 6px 10px;
  ${({ theme }) => css`
    background-color: ${theme.grey.lighter};
  `}
`;

export const Icon = styled(IconAirplane)`
  width: 18px;
  height: 18px;
`;

export const Text = styled.p`
  ${({ theme, withoutIcon }) => css`
    ${
      !withoutIcon &&
      css`
        padding-left: 3px;
      `
    }
    color: ${theme.grey.darker};
    font-size: ${({ theme }) => theme.fontSize.xxs};
  `}
`;
