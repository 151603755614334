import React from 'react';
import { withDiscountProgressiveBadgeExperiment } from '@catalogo/service-americanas-common/src/hocs/experiments/discount-progressive-badge-experiment';
import { IInjectedProps } from '@catalogo/service-americanas-common/src/hocs/experiments/discount-progressive-badge-experiment/types';
import { IProps } from './types';
import * as Styled from './styles';

export const DiscountProgressiveBadge: React.FC<IProps & IInjectedProps> = ({
  discountProgressiveStamp,
  isSmallFont,
}) => {
  if (!discountProgressiveStamp) {
    return <></>;
  }

  return (
    <Styled.Wrapper $isSmallFont={isSmallFont}>
      <span>leve {discountProgressiveStamp?.quantity}, pague menos</span>
    </Styled.Wrapper>
  );
};

export default withDiscountProgressiveBadgeExperiment(DiscountProgressiveBadge);
