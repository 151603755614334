import React from 'react';
import styled from 'styled-components';
import { DisclaimerProps } from '../helper/interfaces';

export const Disclaimer: React.FC<DisclaimerProps> = ({ disclaimerColor }) => (
  <Text color={disclaimerColor}>publicidade</Text>
);

const Text = styled.p<{ color: string }>`
  font-size: 8px;
  font-weight: 400;
  text-align: end;
  text-transform: uppercase;
  padding: 4px 22px 4px 0px;
  color: ${({ color }) => color};
`;

export default Disclaimer;
