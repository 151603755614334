import React from 'react';
import { ListPrice, RangePrice } from '@catalogo/theme-product-card';
import { IPriceInfoRange } from './types';
import * as Styled from './styles';

export const PriceInfoRange: React.FC<IPriceInfoRange> = props => {
  const { promotionalPrice, range } = props;
  return (
    <Styled.Container className="price-info-range">
      {range ? <RangePrice range={range} /> : <ListPrice promotionalPrice={promotionalPrice} />}
    </Styled.Container>
  );
};

export default PriceInfoRange;
