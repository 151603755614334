import React from 'react';
import { getIn } from 'seamless-immutable';
import * as Styled from './styles';

export const EventStamp = ({ stamps }) => {
  const eventStamps = getIn(stamps, ['eventStamp'], '');

  return <>{!!eventStamps && <Styled.EventStampImg src={eventStamps} />}</>;
};

export default EventStamp;
