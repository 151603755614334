import React from 'react';
import { Carousel } from '@catalogo/theme-carousel';
import RecProductCard from '@catalogo/ui-americanas-mobile-rec-product-card';
import { IProps } from './types';
import * as Styled from './styles';

export const ProductGridCarousel: React.FC<IProps> = ({ title, seeMoreLink, products }) => {
  if (products?.length === 0) {
    return <></>;
  }

  return (
    <div id="product-grid-carousel">
      <Styled.TitleWrapper>
        <Styled.Title>{title}</Styled.Title>
        {seeMoreLink && <Styled.SeeMore href={seeMoreLink}>ver mais</Styled.SeeMore>}
      </Styled.TitleWrapper>
      <Carousel gap={15}>
        {products?.map(({ product }) => (
          <Styled.ProductContainer key={product?.id}>
            <RecProductCard id={product?.id} product={product} />
          </Styled.ProductContainer>
        ))}
      </Carousel>
    </div>
  );
};
