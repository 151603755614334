import React from 'react';
import { RatingElement } from '@catalogo/ui-americanas-mobile-rating';
import { IRatingProps } from '@catalogo/theme-product-card/src/components/atoms/rating/types';

export const RatingMobile: React.FC<IRatingProps> = props => {
  const { rating, fontSize = 12, size = 15, hasCount = true, className = '' } = props;

  return <RatingElement rating={rating} fontSize={fontSize} size={size} hasCount={hasCount} className={className} />;
};

export default RatingMobile;
