import React from 'react';
import styled from 'styled-components';
import { withMetrics } from '@catalogo/core-metrics';
import compose from 'lodash/fp/compose';
import Title from '@catalogo/ui-americanas-mobile-banner-vitrine-ads/src/components/title';
import B2wAds from '@catalogo/ui-americanas-desktop-b2wads-google';
import Carousel from '@catalogo/ui-americanas-mobile-banner-vitrine-ads/src/components/carousel';
import Disclaimer from '@catalogo/ui-americanas-mobile-banner-vitrine-ads/src/components/disclaimer';
import { BannerVitrineAdsProps } from '@catalogo/ui-americanas-mobile-banner-vitrine-ads/src/helper/interfaces';
import withBannerVitrineAds from '@catalogo/service-americanas-spacey/src/hocs/banner-vitrine-ads';

export const BannerVitrineAds: React.FC<BannerVitrineAdsProps> = props => {
  const {
    componentTitle,
    linkSeeAll,
    b2wAdsBanner,
    backgroundColor,
    products,
    publication,
    productCardQuery,
    disclaimerColor,
    isMobile,
  } = props;

  return (
    <>
      <Title componentTitle={componentTitle} linkSeeAll={linkSeeAll} />
      <Component backgroundColor={backgroundColor}>
        <Wrapper>
          <Banner>
            <B2wAds publication={b2wAdsBanner} />
          </Banner>
          <CarouselWrapper>
            <Carousel
              products={products}
              publication={publication}
              productCardQuery={productCardQuery}
              isMobile={isMobile}
            />
          </CarouselWrapper>
        </Wrapper>
        <Disclaimer disclaimerColor={disclaimerColor} />
      </Component>
    </>
  );
};

const Component = styled.div<{ backgroundColor: string }>`
  height: 424px;
  border-radius: 16px;
  padding: 20px 20px 0px 30px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Banner = styled.div`
  width: 250px;
  height: 381px;
  margin-right: 21px;
  align-self: center;
`;

const CarouselWrapper = styled.div`
  width: 700px;
`;

export default compose(withMetrics, withBannerVitrineAds)(BannerVitrineAds);
