import React from 'react';
import { ISalesPriceProps } from './types';
import * as Styled from './styles';

export const SalesPrice: React.FC<ISalesPriceProps> = props => {
  const { price, className = '' } = props;
  const classNameStr = `${className} sales-price`.trim();

  return price ? <Styled.Price className={classNameStr}>{price}</Styled.Price> : <></>;
};

export default SalesPrice;
