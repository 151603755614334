import React from 'react';
import { withDevice, WithDeviceProps } from '@catalogo/core-connect/device';
import RatingDesktop from './desktop';
import RatingMobile from './mobile';
import { IRatingProps } from './types';

export const Rating: React.FC<IRatingProps & WithDeviceProps> = props => {
  const { device, className = '' } = props;
  const classNameStr = `${className} rating`.trim();

  if (device?.toLowerCase() === 'desktop') {
    return <RatingDesktop {...props} className={classNameStr} />;
  }

  return <RatingMobile {...props} className={classNameStr} />;
};

export default withDevice(Rating);
