import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { OutOfStockText, ImageInfo, ProductInfo } from '@catalogo/theme-product-card';

const OutofStockCard = props => (
  <Wrapper>
    <Nav to={props.url}>
      <ImageInfo {...props} />
      <StyledProductInfo {...props} color="#999" fontSize="12px" />
      <OutOfStockText />
    </Nav>
  </Wrapper>
);

const Wrapper = styled.div`
  margin: 0px auto;

  .image-info {
    margin-bottom: 12px;
  }
`;

const StyledProductInfo = styled(ProductInfo)`
  margin-top: 58px;
  margin-bottom: 16px;
  padding: 0 16px;
`;

export default OutofStockCard;
