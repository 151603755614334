import React from 'react';
import { DiscountBadge } from '@catalogo/theme-badges';
import { ListPrice, SalesPrice, Installment } from '@catalogo/theme-product-card';
import { IPriceInfoProps } from '../types';
import * as Styled from './styles';

export const PriceInfoMobile: React.FC<IPriceInfoProps> = props => {
  const { price, isNurslingProduct, className = '' } = props;

  return (
    <Styled.Wrapper className={className}>
      <Styled.ContainerSalesDiscount className="price-info-container-discount">
        <SalesPrice price={price?.price} />
        {!isNurslingProduct && <DiscountBadge discount={price?.discount} />}
      </Styled.ContainerSalesDiscount>
      <Styled.ContainerPriceInstalmentCash className="price-info-container-installment-cash">
        <ListPrice promotionalPrice={price?.promotionalPrice} />
        <Installment installmentPhase={price?.installmentPhase} />
      </Styled.ContainerPriceInstalmentCash>
    </Styled.Wrapper>
  );
};

export default PriceInfoMobile;
