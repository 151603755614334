import React from 'react';
import { getIn } from 'seamless-immutable';
import * as Styled from './styles';

const SponsoredBadge = ({ stamps }) => {
  const isSponsored = getIn(stamps, ['isSponsored'], false);

  return (
    <>
      {!!isSponsored && (
        <Styled.Badge>
          <Styled.BadgeText>Patrocinado</Styled.BadgeText>
        </Styled.Badge>
      )}
    </>
  );
};

export default SponsoredBadge;
