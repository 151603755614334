import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { getIn } from 'seamless-immutable';
import { Stars } from '@catalogo/theme-stars';

export const RatingElement = ({ rating, fontSize, size, className, hasCount }) => {
  const reviews = getIn(rating, ['reviews'], 0);

  return (
    <Container className={className}>
      <Stars rating={getIn(rating, ['average'], 0)} size={size} />
      {hasCount && <Count fontSize={fontSize}>({reviews})</Count>}
    </Container>
  );
};

const ProductRating = ({ rating, size, fontSize = 14, url = '#card-reviews-title', hasCount = true }) => (
  <a href={url}>
    <RatingElement rating={rating} size={size} fontSize={fontSize} hasCount={hasCount} />
  </a>
);

ProductRating.defaultProps = {
  size: 22,
};

ProductRating.propTypes = {
  rating: PropTypes.object,
  id: PropTypes.number,
};

export default ProductRating;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

const Count = styled.span`
  ${({ fontSize }) => css`
    font-size: ${fontSize}px;
    color: #999;
    margin-left: 3px;
    font-weight: 300;
    line-height: ${fontSize}px;
  `};
`;
