import React from 'react';
import { useTestAB } from '@catalogo/core-experiments';
import { IProps as IComponentProps } from '@catalogo/ui-americanas-mobile-badges/src/components/discount-progressive/types';
import { IInjectedProps } from './types';

export const withDiscountProgressiveBadgeExperiment = <P extends IComponentProps>(
  Component: React.FC<P & IInjectedProps>
) => (props: P) => {
  const { discountProgressiveStamp } = props;
  const experiment = discountProgressiveStamp ? useTestAB('EXP_NS_08_DISCOUNT_PROGRESSIVE_BADGE') : 'OLD';

  if (experiment?.toUpperCase() !== 'NEW') {
    return <></>;
  }
  return <Component {...props} />;
};
