import styled, { css } from 'styled-components';

export const SkuInfo = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xxs};

    @media (min-width: 1024px) {
      font-size: ${theme.fontSize.xs};
    }
  `}
`;
