import React from 'react';
import { IAvailableSkuProps } from './types';
import * as Styled from './styles';

export const AvailableSku: React.FC<IAvailableSkuProps> = props => {
  const { skuVariations, className = '' } = props;
  const classNameStr = `${className} available-sku`.trim();

  return skuVariations ? <Styled.SkuInfo className={classNameStr}>{skuVariations}</Styled.SkuInfo> : <></>;
};

export default AvailableSku;
