import React from 'react';
import { getIn } from 'seamless-immutable';
import * as Styled from './styles';

export const PreSaleBadge = ({ stamps }) => {
  const isPreSale = getIn(stamps, ['isPreSale'], false);

  return (
    <>
      {!!isPreSale && (
        <Styled.Badge className="pre-sale-badge">
          <Styled.Text className="pre-sale-badge-text">pré-venda</Styled.Text>
        </Styled.Badge>
      )}
    </>
  );
};

export default PreSaleBadge;
