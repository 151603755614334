import { productImage } from '@catalogo/service-americanas-product-card/src/queries/product-image.graphql';
import { useQuery } from '@apollo/client';

export const useProductImage = ({ productId }: { productId: string }) => {
  const { data } = useQuery(productImage, {
    variables: { productId },
  });

  return { data };
};
