import styled, { css } from 'styled-components';
import { Text } from '@catalogo/theme-text';

export const PromotionalPrice = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: ${theme.fontSize.xs};
    font-weight: 700;
    word-break: break-word;

    @media (min-width: 1024px) {
      font-size: ${theme.fontSize.sm};
      margin-bottom: 4px;
    }
  `}
`;
