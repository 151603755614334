import React from 'react';
import { ICashbackMessageProps } from './types';
import * as Styled from './styles';

export const CashbackMessage: React.FC<ICashbackMessageProps> = props => {
  const { cashback, isSuperCashback, className = '' } = props;
  const classNameStr = `${className} cashback-message`.trim();

  return cashback && isSuperCashback ? (
    <Styled.Installment className={classNameStr}>
      <Styled.Text className="cashback-message-text">
        receba de volta <Styled.Discount className="cashback-message-discount">{cashback}</Styled.Discount> na Ame
      </Styled.Text>
    </Styled.Installment>
  ) : (
    <></>
  );
};

export default CashbackMessage;
