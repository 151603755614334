import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  height: 100%;
  width: 100%;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    padding: 16px;
    width: calc(100% - 32px);
    max-width: 180px;
    height: auto;
  }
`;
