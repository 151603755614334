import React from 'react';
import { IIstallmentProps } from './types';
import * as Styled from './styles';

export const Installment: React.FC<IIstallmentProps> = props => {
  const { installmentPhase, className = '' } = props;
  const classNameStr = `${className} installment`.trim();

  return installmentPhase ? (
    <Styled.InstallmentText className={classNameStr}>{installmentPhase}</Styled.InstallmentText>
  ) : (
    <></>
  );
};

export default Installment;
