import React from 'react';
import { IListPriceProps } from './types';
import * as Styled from './styles';

export const ListPrice: React.FC<IListPriceProps> = props => {
  const { promotionalPrice, className = '' } = props;
  const classNameStr = `${className} list-price`.trim();

  return <Styled.PromotionalPrice className={classNameStr}>{promotionalPrice}</Styled.PromotionalPrice>;
};

export default ListPrice;
