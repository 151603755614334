import styled, { css } from 'styled-components';
import { Text as ThemeText } from '@catalogo/theme-text';

export const Installment = styled.div`
  @media (min-width: 1024px) {
    margin-top: 16px;
  }
`;

export const Text = styled(ThemeText)`
  ${({ theme }) => css`
    color: ${theme.grey.dark};
    font-size: ${theme.fontSize.xxs};

    @media (min-width: 1024px) {
      font-size: ${theme.fontSize.xs};
    }
  `}
`;

export const Discount = styled(ThemeText)`
  ${({ theme }) => css`
    color: ${theme.card.green};

    @media (min-width: 1024px) {
      color: ${theme.card.badge};
    }
  `}
`;
