import React from 'react';
import { useTestAB } from '@catalogo/core-experiments';
import { mergeComponents } from '@catalogo/service-americanas-common/src/helpers/template';
import useDeferedBannerVitrineAds from '../hook/defered-banner-vitrine-ads';
import { HocType, withBannerVitrineAdsPros, formatPropsForBannerVitrineAds } from '../helper/banner-vitrine-ads';

export const withBannerVitrineAds: HocType<withBannerVitrineAdsPros> = Component => props => {
  if (!props?.publication || !props?.page) {
    return null;
  }

  const experiment = useTestAB('EXP_CAT_BANNER_VITRINE_ADS');

  if (experiment?.toUpperCase() !== 'NEW') {
    return null;
  }

  const { publication, page, device } = props;

  const { products, dataDefered } = useDeferedBannerVitrineAds(page, publication?.marsPosition);

  const componentProps = formatPropsForBannerVitrineAds(publication, products, device);

  props?.dispatchMetrics('recommendation:load', mergeComponents({ page }, dataDefered));

  return <Component {...componentProps} />;
};

export default withBannerVitrineAds;
