import React from 'react';
import styled from 'styled-components';
import { AdsBadge, UsedBadge } from '@catalogo/theme-badges';

export const BadgesTopInfo = ({ className, rating, ...cardProps }) => (
  <Wrapper>
    <AdsBadge {...cardProps} />
    <UsedBadge {...cardProps} />
  </Wrapper>
);

export default BadgesTopInfo;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
  margin-bottom: 12px;
  padding: 0 16px;
  min-height: 28px;

  .ads-badge,
  .used-badge {
    &-text {
      font-size: ${({ theme }) => theme.fontSize.xs};
    }
  }
`;
