import React from 'react';
import { IProductNameProps } from './types';
import * as Styled from './styles';

export const ProductName: React.FC<IProductNameProps> = props => {
  const { name, className = '' } = props;
  const classNameStr = `${className} product-name`.trim();

  return <Styled.Name className={classNameStr}>{name}</Styled.Name>;
};

export default ProductName;
