import React from 'react';
import RecProductCard from '@catalogo/ui-americanas-mobile-rec-product-card';
import styled from 'styled-components';
import { createRecProductCardType } from './interfaces';

export const createRecProductCard: createRecProductCardType = (publication, productCardQuery) => ({
  id,
  queryString,
  trackingUrl,
  sellerId,
  isAd,
}) => (
  <RecProductCardContainer key={id}>
    <RecProductCard
      id={id}
      sellerId={sellerId}
      publication={publication}
      queryString={queryString}
      trackingUrl={trackingUrl}
      query={productCardQuery}
      isRec={true}
      isAd={isAd}
      isBannerVitrineAds={true}
    />
  </RecProductCardContainer>
);

const RecProductCardContainer = styled.div`
  .card {
    height: 100%;
    padding-left: 12px;
  }
`;
