import React from 'react';
import { ProductName, AvailableSku, Rating } from '@catalogo/theme-product-card';
import { IProductInfoProps } from './types';
import * as Styled from './styles';

export const ProductInfo: React.FC<IProductInfoProps> = props => {
  const {
    name,
    rating,
    color,
    isMarketProduct,
    skuVariations,
    showAvailableSku = false,
    fontSize = 12,
    size = 15,
    hasCount = true,
    className = '',
  } = props;

  const classNameStr = `${className} product-info`.trim();

  return (
    <Styled.Wrapper className={classNameStr}>
      <ProductName name={name} />
      {showAvailableSku && <AvailableSku skuVariations={skuVariations} />}
      {!isMarketProduct && <Rating rating={rating} color={color} size={size} fontSize={fontSize} hasCount={hasCount} />}
    </Styled.Wrapper>
  );
};

export default ProductInfo;
