import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 12px;
  padding: 0 16px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  height: 17px;
`;

export const WrapperDiscount = styled.div`
  margin-left: 6px;

  .discount-badge {
    &-text {
      font-size: ${({ theme }) => theme.fontSize.xxs};
    }
  }
`;
