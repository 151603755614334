import styled, { css } from 'styled-components';

export const Badge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px 8px;
  height: 28px;
  ${({ theme }) => css`
    background-color: ${theme.grey.lighter};
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: ${({ theme }) => theme.fontSize.xxs};
  `}
`;
