import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 8px;
`;

export const ContainerPriceInstalmentCash = styled.div`
  display: grid;
  justify-content: start;
  gap: 2px;
`;

export const ContainerSalesDiscount = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  &:empty {
    height: 14px;
  }
`;
