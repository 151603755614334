import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import withProductCard from '@catalogo/service-americanas-product-card/src/hocs/product-card';
import { withProduct } from '@catalogo/core-connect/product';
import { Placeholder } from './placeholder';
import InStockCard from './components/organisms/inStockCard';
import OutOfStockCard from './components/organisms/outOfStockCard';

export const RecProductCard = props => {
  const { outOfStock, className, isLoading } = props;

  if (isLoading) {
    return <Placeholder />;
  }

  return (
    <Wrapper className={className}>
      {!outOfStock && <InStockCard {...props} className="" />}
      {outOfStock && <OutOfStockCard {...props} className="" />}
    </Wrapper>
  );
};

export default compose(withProduct, withProductCard)(RecProductCard);

const Wrapper = styled.div`
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  height: calc(100% - 6px);
  width: 232px;
`;
