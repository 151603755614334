import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Carousel as TemplateCarousel } from '@catalogo/theme-carousel-with-bullets';
import ZionProduct from '@catalogo/ui-americanas-desktop-zion-product';

class Carousel extends React.Component {
  render() {
    const { publication } = this.props;
    if (!publication || publication?.children?.length === 0) {
      return null;
    }
    const { showcaseTitle = '', title = '' } = publication;
    return (
      <Container>
        {(!!showcaseTitle || !!title) && <Description>{showcaseTitle || title}</Description>}
        <TemplateCarousel gap={20}>
          {publication.children.map(publicationChild => (
            <ZionProduct publication={publicationChild} key={`${publicationChild.productId}-${publicationChild._id}`} />
          ))}
        </TemplateCarousel>
      </Container>
    );
  }
}

Carousel.propTypes = {
  publication: PropTypes.object,
};

const Description = styled.h2`
  font-weight: bold;
  font-size: 26px;
  line-height: 1.4;
  color: #666;
  padding: 20px 0px 10px 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Carousel;
