import React from 'react';
import styled from 'styled-components';
import { TitleProps } from '../helper/interfaces';

export const Title: React.FC<TitleProps> = ({ componentTitle, linkSeeAll }) => (
  <ContainerTitle>
    <H2>{componentTitle}</H2>
    <BtnSeeAll href={linkSeeAll}>ver tudo</BtnSeeAll>
  </ContainerTitle>
);

const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 14px;
  justify-content: space-between;
`;

const H2 = styled.h2`
  font-size: 18px;
  font-weight: bold;
  text-transform: lowercase;
  color: ${({ theme }) => theme.fontColor};
`;

const BtnSeeAll = styled.a`
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  background-color: transparent;
  color: ${({ theme }) => theme.primaryColor};
`;

export default Title;
