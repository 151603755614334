import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { ImageInfo, PriceInfo, ProductInfo } from '@catalogo/theme-product-card';

import BadgesTopInfo from '../molecules/badges-top-info';
import BadgesBottomInfo from '../molecules/badges-bottom-info';

const InStockCard = props => (
  <Wrapper>
    <Link to={props.url}>
      <ImageInfo {...props} />
      <BadgesTopInfo {...props} />
      <ProductInfo {...props} color="#999" fontSize="12px" />
      <PriceInfo {...props} />
      <BadgesBottomInfo {...props} />
    </Link>
  </Wrapper>
);

export default InStockCard;

const Wrapper = styled.div``;

const Link = styled(Nav)`
  flex-direction: column;
  outline: none;

  &:focus-visible::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 6px;
    pointer-events: none;
    border-radius: 16px;
    border: 3px solid #f80032;
  }
`;
