import styled, { css } from 'styled-components';

export const Name = styled.h3`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: ${theme.fontSize.xxs};
    text-transform: ${theme.productCard.textTransform};
    font-weight: ${theme.productCard.textWeigth};
    line-height: ${theme.fontSize.xs};
    height: 28px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (min-width: 1024px) {
      font-size: ${theme.fontSize.xs};
      line-height: ${theme.fontSize.sm};
      height: 32px;
    }
  `}
`;
