import styled, { css } from 'styled-components';

export const Badge = styled.div`
  ${({ theme }) => css`
    text-transform: ${theme.titleTransform};
  `}
  background-color: rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 8px;
  width: 100%;
`;

export const BadgeText = styled.p`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: ${theme.fontSize.xxs};
  `}
`;
