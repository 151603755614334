import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { OutOfStockText, ImageInfo, ProductInfo } from '@catalogo/theme-product-card';

const OutOfStockCard = ({ url, ...cardProps }) => (
  <Wrapper>
    <Nav to={url}>
      <ImageInfo {...cardProps} />
      <StyledProductInfo {...cardProps} />
      <OutOfStockText />
    </Nav>
  </Wrapper>
);

const Wrapper = styled.div``;

const StyledProductInfo = styled(ProductInfo)`
  margin-bottom: 8px;
  padding: 16px 16px 0;
`;

export default OutOfStockCard;
