import { useQuery } from '@apollo/client';
import { getDeferedBannerVitrineAds } from '../queries/defered-banner-vitrine-ads.graphql';
import { Page } from '../helper/banner-vitrine-ads';

export const useDeferedBannerVitrineAds = (page: Page, marsPosition: string) => {
  const variables = {
    categoryId: page?.breadcrumb?.[0]?.id,
    recPlacements: [marsPosition],
    term: page?.name,
    pageType: 'category',
  };

  const { data } = useQuery(getDeferedBannerVitrineAds, {
    variables,
    skip: false,
    ssr: false,
  });

  return {
    dataDefered: data,
    products: data?.recommendations?.components?.[marsPosition]?.products,
  };
};

export default useDeferedBannerVitrineAds;
