import React from 'react';
import * as Styled from './styles';

export const OutOfStockText: React.FC = () => (
  <Styled.Wrapper className="outofstock-text">
    <Styled.Text className="outofstock-text-content">Ops! Já vendemos todo o estoque.</Styled.Text>
  </Styled.Wrapper>
);

export default OutOfStockText;
