import React from 'react';
import styled, { css } from 'styled-components';

export const Shimmer = ({ border }) => (
  <Wrapper border={border}>
    <Animate />
  </Wrapper>
);

export default Shimmer;

const Wrapper = styled.div`
  ${({ border }) => css`
    width: 100%;
    height: 100%;
    background: #d9d9d9;
    ${border &&
    css`
      border-radius: ${border};
    `};
    overflow: hidden;
    animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);

    @keyframes fullView {
      100% {
        width: 100%;
      }
    }
  `}
`;

const Animate = styled.div`
  width: 100%;
  height: 100%;
  background: #d9d9d9;
  opacity: 30%;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(135deg, #d9d9d9 30%, #fafafa 50%, #d9d9d9 70%);
  background-size: 300% 100%;
  @keyframes shimmer {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0% 0;
    }
  }
`;
