import React from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from './components/spinner';
import { Shimmer } from './components/shimmer';

export const Skeleton = ({ width = '500px', height = '100px', spinner = false, size, border }) => (
  <Container width={width} height={height}>
    <Shimmer border={border} />
    {spinner && <SpinnerUI size={size} />}
  </Container>
);

export default Skeleton;

const Container = styled.div`
  ${({ width, height }) => css`
    width: ${width};
    height: ${height};
    position: relative;
    overflow: hidden;
  `}
`;

const SpinnerUI = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
