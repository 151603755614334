import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Carousel as TemplateCarousel } from '@catalogo/theme-carousel';
import ZionProduct from '@catalogo/ui-americanas-mobile-zion-product';

class Carousel extends React.Component {
  render() {
    const { publication } = this.props;

    if (!publication || publication?.children?.length === 0) {
      return null;
    }

    const { showcaseTitle } = publication;
    return (
      <React.Fragment>
        <Container>
          {showcaseTitle && <Description>{showcaseTitle}</Description>}
          <TemplateCarousel gap={15}>
            {publication.children.map(publicationChild => (
              <ZionProduct
                publication={publicationChild}
                key={`${publicationChild.productId}-${publicationChild._id}`}
              />
            ))}
          </TemplateCarousel>
        </Container>
      </React.Fragment>
    );
  }
}

Carousel.propTypes = {
  publication: PropTypes.object,
};

const Description = styled.h2`
  font-weight: bold;
  font-size: 26px;
  line-height: 1.4;
  color: #666;
  padding: 20px 0px 10px 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 10px);
`;

export default Carousel;
