import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import productCardQuery from '@catalogo/service-americanas-product-card/src/queries/product-card-search.graphql';
import { LazyPicture } from '@catalogo/theme-lazy-picture';
import { withProductImage } from '@catalogo/service-americanas-product-card/src/hocs/productImage';
import RecProductCard from '@catalogo/ui-americanas-desktop-rec-product-card';
import ProductCard from './component/product-card';

export const ZionProduct = ({ productId, isOnlyImage, url, image, publication, routeId, index, position }) => (
  <>
    {isOnlyImage ? (
      <Wrapper>
        <NavUI to={url}>
          <LazyPicture
            alt="outra imagem do produto"
            lazy
            sources={{
              desktop: {
                src: image,
                ratio: '1:1',
              },
            }}
          />
        </NavUI>
      </Wrapper>
    ) : publication?.zionSlideShow || publication?.viewType === 'grid' ? (
      <Wrapper>
        <ProductCard
          routeId={routeId}
          index={index}
          id={productId}
          query={productCardQuery}
          queryString=""
          trackingUrl=""
          position={position}
          publication={publication}
          sources={{
            desktop: {
              src: image,
              ratio: '1:1',
            },
          }}
        />
      </Wrapper>
    ) : (
      <RecProductCard
        routeId={routeId}
        key={productId}
        index={index}
        id={productId}
        query={productCardQuery}
        sellerId=""
        queryString=""
        trackingUrl=""
        position={position}
        publication={publication}
        isRec={true}
        isAd={false}
      />
    )}
  </>
);

export default withProductImage(ZionProduct);

const Wrapper = styled.div`
  width: 100%;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  & > div:nth-of-type(1) {
    width: 100%;
  }
`;

const NavUI = styled(Nav)`
  width: 75%;
`;
