import React from 'react';
import * as Styled from './styles';

export const DiscountBadge = ({ discount }) => (
  <>
    {!!discount && (
      <Styled.Badge className="discount-badge">
        <Styled.ArrowDiscount fill="#fff" className="discount-badge-icon" />
        <Styled.Text className="discount-badge-text">{discount}</Styled.Text>
      </Styled.Badge>
    )}
  </>
);

export default DiscountBadge;
