import React from 'react';
import * as Styled from './styles';

export const MarketBadge = ({ isMarketProduct }) => (
  <>
    {!!isMarketProduct && (
      <Styled.Badge className="market-badge">
        <Styled.Icon className="market-badge-icon" />
        <Styled.Text className="market-badge-text">mercado</Styled.Text>
      </Styled.Badge>
    )}
  </>
);

export default MarketBadge;
