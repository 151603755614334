import React from 'react';
import { withConfig } from '@catalogo/core-connect/config';
import { getIn } from 'seamless-immutable';
import * as Styled from './styles';

export const RecommendedBadge = ({ stamps, config }) => {
  const isRecommended = getIn(stamps, ['isRecommended'], false);

  const getIconBrand = brand => {
    if (brand === 'suba') {
      return <Styled.SubaIcon className="recommended-badge-icon" />;
    } else if (brand === 'shop') {
      return <Styled.ShopIcon className="recommended-badge-icon" />;
    }
    return <Styled.AcomIcon className="recommended-badge-icon" />;
  };
  return (
    <>
      {!!isRecommended && (
        <Styled.Badge className="recommended-badge">
          {getIconBrand(config?.brand)}
          <Styled.Text className="recommended-badge-text">indica</Styled.Text>
        </Styled.Badge>
      )}
    </>
  );
};

export default withConfig(RecommendedBadge);
