import React from 'react';
import { withDevice, WithDeviceProps } from '@catalogo/core-connect/device';
import PriceInfoDesktop from './desktop';
import PriceInfoMobile from './mobile';
import { IPriceInfoProps } from './types';

export const PriceInfo: React.FC<IPriceInfoProps & WithDeviceProps> = props => {
  const { device, className = '' } = props;
  const classNameStr = `${className} price-info`.trim();

  if (device?.toLowerCase() === 'desktop') {
    return <PriceInfoDesktop {...props} className={classNameStr} />;
  }

  return <PriceInfoMobile {...props} className={classNameStr} />;
};

export default withDevice(PriceInfo);
