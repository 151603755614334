import productCardQuery from '@catalogo/service-americanas-product-card/src/queries/product-card-search.graphql';

export const formatPropsForBannerVitrineAds = (publication: Publication, products: unknown[], device: string) => {
  const {
    componentTitle,
    linkSeeAll,
    adUnitPath,
    networkCode,
    backgroundColor,
    disclaimerColor,
    btnSeeAllColor,
    publicationId,
    ratioMobile,
    ratioDesktop,
  } = publication;

  const isMobile = device === 'mobile' ? true : false;

  return {
    componentTitle,
    linkSeeAll,
    btnSeeAllColor,
    backgroundColor,
    disclaimerColor,
    b2wAdsBanner: {
      adUnitPath,
      networkCode,
      ratio: isMobile ? ratioMobile : ratioDesktop,
      _id: publicationId,
    },
    publication,
    products,
    productCardQuery: productCardQuery,
    isMobile: isMobile,
  };
};

export type HocType<PropsType> = (Component: React.FC<any>) => React.FC<PropsType>;

export interface withBannerVitrineAdsPros {
  publication: Publication;
  page: Page;
  device: 'desktop' | 'mobile';
  dispatchMetrics: (type: string, body: Record<string, unknown>) => void;
}

export interface Publication {
  componentTitle: string;
  linkSeeAll: string;
  adUnitPath: string;
  networkCode: string;
  backgroundColor: string;
  disclaimerColor: string;
  btnSeeAllColor: string;
  publicationId: string;
  marsPosition: string;
  ratioDesktop: string;
  ratioMobile: string;
}

export interface Page {
  breadcrumb: [{ id: number }];
  name: string;
}
