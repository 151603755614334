import React from 'react';
import styled from 'styled-components';
import { Carousel as CardContainerMobile } from '@catalogo/theme-carousel';
import CardContainerDesktop from '@catalogo/theme-carousel-with-bullets';
import Spinner from '@catalogo/theme-skeleton/src/components/spinner';
import { createRecProductCard } from '../helper/functions';

export const Carousel = ({ products, publication, productCardQuery, isMobile }) => {
  const cards = products?.map(createRecProductCard(publication, productCardQuery));
  const CurrentCardContainer = isMobile ? CardContainerMobile : CardContainerDesktop;

  const card = (
    <CurrentCardContainer gap={3} isBannerVitrineAds={true}>
      {cards}
    </CurrentCardContainer>
  );

  const loading = (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );

  return <CarouselContainer>{cards ? card : loading}</CarouselContainer>;
};

const CarouselContainer = styled.div`
  padding-top: 12px;

  @media (min-width: 1200px) {
    padding-top: 0px;
  }
`;

const SpinnerContainer = styled.div`
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Carousel;
