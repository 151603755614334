import React from 'react';
import ProductRating from '@catalogo/ui-americanas-desktop-rating';
import { IRatingProps } from '@catalogo/theme-product-card/src/components/atoms/rating/types';

export const RatingDesktop: React.FC<IRatingProps> = props => {
  const { rating, size, fontSize = '12px', color = '#999', hasCount = true, className = '' } = props;

  return (
    <div className={className}>
      <ProductRating rating={rating} size={size as number} fontSize={fontSize} color={color} hasCount={hasCount} />
    </div>
  );
};

export default RatingDesktop;
