import React from 'react';
import { LazyPicture } from '@catalogo/theme-lazy-picture';
import { ICardImageProps } from './types';
import * as Styled from './styles';

export const CardImage: React.FC<ICardImageProps> = props => {
  const {
    imageSource,
    name,
    className = '',
    backgroundColor = '#fafafa',
    errorPlaceholderSize = '142px',
    fetchPriority = 'low',
  } = props;

  const classNameStr = `${className} card-image`.trim();

  return (
    <Styled.Wrapper className={classNameStr}>
      <LazyPicture
        sources={imageSource}
        alt={name}
        backgroundColor={backgroundColor}
        errorPlaceholderSize={errorPlaceholderSize}
        fetchPriority={fetchPriority}
      />
    </Styled.Wrapper>
  );
};

export default CardImage;
