import React from 'react';
import { EventStamp } from '@catalogo/theme-badges';
import { CardImage } from '@catalogo/theme-product-card';
import { ICardImageProps } from '@catalogo/theme-product-card/src/components/atoms/card-image/types';
import { IImageInfoProps } from './types';

import * as Styled from './styles';

export const ImageInfo: React.FC<IImageInfoProps & ICardImageProps> = props => {
  const { imageSource, name, className = '', backgroundColor, errorPlaceholderSize, fetchPriority, stamps } = props;

  const classNameStr = `${className} image-info`.trim();

  return (
    <Styled.Wrapper className={classNameStr}>
      <Styled.WrapperStamp className="image-info-stamp">
        <EventStamp stamps={stamps} />
      </Styled.WrapperStamp>
      <CardImage
        imageSource={imageSource}
        name={name}
        className={className}
        backgroundColor={backgroundColor}
        errorPlaceholderSize={errorPlaceholderSize}
        fetchPriority={fetchPriority}
      />
    </Styled.Wrapper>
  );
};

export default ImageInfo;
