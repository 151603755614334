import React from 'react';
import { getIn } from 'seamless-immutable';
import * as Styled from './styles';

export const InternationalBadge = ({ stamps }) => {
  const isInternational = getIn(stamps, ['isInternational'], false);

  return (
    <>
      {!!isInternational && (
        <Styled.Badge className="international-badge">
          <Styled.Icon fill="#f80032" className="international-badge-icon" />
          <Styled.Text className="international-badge-text">internacional</Styled.Text>
        </Styled.Badge>
      )}
    </>
  );
};

export default InternationalBadge;
