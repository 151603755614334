import styled, { css } from 'styled-components';
import { Text } from '@catalogo/theme-text';

export const RangePriceContent = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: ${theme.fontSize.xxs};

    > strong {
      font-weight: 700;
      font-size: ${theme.fontSize.xs};
    }

    @media (min-width: 1024px) {
      font-size: ${theme.fontSize.md};
      > strong {
        font-size: ${theme.fontSize.lg};
      }
    }
  `}
`;
