import React from 'react';
import { IRangePriceProps } from './types';
import * as Styled from './styles';

export const RangePrice: React.FC<IRangePriceProps> = props => {
  const { range } = props;
  return range ? (
    <Styled.RangePriceContent className="range-price" dangerouslySetInnerHTML={{ __html: range }} />
  ) : (
    <></>
  );
};

export default RangePrice;
