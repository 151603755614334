import styled, { css } from 'styled-components';
import AcomBrand from '@catalogo/ui-americanas-desktop-svg/brand.svg';
import ShopBrand from '@catalogo/ui-shoptime-desktop-svg/icon-brand.svg';
import SubaBrand from '@catalogo/ui-submarino-desktop-svg/brand.svg';

export const Badge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px 8px;
  height: 28px;
  ${({ theme }) => css`
    background-color: ${theme.grey.lighter};
  `}
`;

export const AcomIcon = styled(AcomBrand)`
  width: 10px;
  height: 14px;
  fill: ${({ theme }) => theme.primaryColor};
`;
export const ShopIcon = styled(ShopBrand)`
  width: 10px;
  height: 14px;
  fill: ${({ theme }) => theme.primaryColor};
`;
export const SubaIcon = styled(SubaBrand)`
  width: 10px;
  height: 14px;
  fill: ${({ theme }) => theme.primaryColor};
`;

export const Text = styled.p`
  ${({ theme, withoutIcon }) => css`
    ${!withoutIcon && `padding-left: 4px;`}
    color: ${theme.grey.darker};
    font-size: ${({ theme }) => theme.fontSize.xxs};
  `}
`;
