/* eslint-disable max-statements */
import React from 'react';
import PropTypes from 'prop-types';
import { LazyPicture } from '@catalogo/theme-lazy-picture';
import { stringify } from 'query-string';
import { withRouter } from 'react-router';
import compose from 'lodash/fp/compose';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { Text } from '@catalogo/theme-text';
import { Stars } from '@catalogo/theme-stars';
import { getIn } from 'seamless-immutable';
import { formatPrice } from '@catalogo/core-helpers/price';
import { withProduct } from '@catalogo/core-connect/product';
import { withMetrics } from '@catalogo/core-metrics';
import { withPageType } from '@catalogo/core-connect/page-type';
import { hasCriticalErrors, has404OnPath } from '@catalogo/core-graphql-errors';
import ArrowDiscount from '@catalogo/ui-americanas-desktop-svg/arrow-discount.svg';
import Package from '@catalogo/ui-americanas-desktop-svg/package.svg';
import NoImage from '@catalogo/ui-americanas-desktop-svg/no-image.svg';
import Heart from '@catalogo/ui-americanas-desktop-svg/heart.svg';
import { getProductPathname } from '@catalogo/core-product/src';

class ProductCard extends React.Component {
  renderSponsored() {
    const { sponsored } = getIn(this.props, ['product'], false);

    if (!sponsored) {
      return null;
    }

    return (
      <Sponsored>
        <SponsoredText>Patrocinado</SponsoredText>
      </Sponsored>
    );
  }

  renderInstallment() {
    const zionSlideShow = getIn(this.props, ['publication', 'zionSlideShow']);
    const offer = getIn(this.props, ['product', 'offers', 'result', 0, 'bestPaymentOption']) || {};
    if (offer.type === 'BOLETO') {
      return !zionSlideShow ? (
        <Installment>no boleto</Installment>
      ) : (
        <InstallmentSlideShow>no boleto</InstallmentSlideShow>
      );
    }

    if (offer.type === 'PIX') {
      return !zionSlideShow ? <Installment>com pix</Installment> : <InstallmentSlideShow>com pix</InstallmentSlideShow>;
    }

    const minInstallment = getIn(offer, ['minInstallment', 0]);
    const maxInstallment = getIn(offer, ['maxInstallment', 0]);
    const valueMaxInstallment = formatPrice(getIn(maxInstallment, ['value'], 0));
    const hasInCashDiscount = getIn(offer, ['minInstallment', '0', 'discount', 'value']) > 0;

    if (hasInCashDiscount) {
      return !zionSlideShow ? (
        <Installment>em {getIn(minInstallment, ['quantity'])}x no cartão de crédito</Installment>
      ) : (
        <InstallmentSlideShow>em {getIn(minInstallment, ['quantity'])}x no cartão de crédito</InstallmentSlideShow>
      );
    }

    const maxInstallmentText = `${getIn(maxInstallment, ['quantity'])}x de ${valueMaxInstallment} s/juros`;
    if (!maxInstallment || maxInstallment.quantity === 1) {
      return null;
    }

    return !zionSlideShow ? (
      <Installment>{maxInstallmentText}</Installment>
    ) : (
      <InstallmentSlideShow>{maxInstallmentText}</InstallmentSlideShow>
    );
  }

  getSalesPrice() {
    const { product } = this.props;
    const offer = getIn(product, ['offers', 'result', 0, 'bestPaymentOption']);
    const inCashDiscount = getIn(offer, ['minInstallment', 0, 'discount', 'value']);
    const salesPrice = getIn(offer, ['price']);
    const minQuantityPrice = getIn(offer, ['minInstallment', 0, 'value']);

    if (inCashDiscount) {
      return minQuantityPrice;
    }

    return salesPrice;
  }

  renderSalesPrice() {
    const zionSlideShow = getIn(this.props, ['publication', 'zionSlideShow']);

    return !zionSlideShow ? (
      <Price>{`R$ ${formatPrice(this.getSalesPrice())}`}</Price>
    ) : (
      <PriceSlideShow>{`R$ ${formatPrice(this.getSalesPrice())}`}</PriceSlideShow>
    );
  }

  renderListPrice() {
    const offer = getIn(this.props, ['product', 'offers', 'result', 0]);
    const inCashDiscount = getIn(offer, ['bestPaymentOption', 'minInstallment', '0', 'discount', 'value']);
    const paymentOptionPrice = getIn(this.props, ['product', 'offers', 'result', 0, 'bestPaymentOption', 'price']);

    if (inCashDiscount > 0) {
      return (
        <ListPrice>
          <del>R$ {formatPrice(paymentOptionPrice)}</del>
        </ListPrice>
      );
    }

    const listPrice = getIn(offer, ['listPrice'], null);

    if (listPrice > this.getSalesPrice()) {
      return (
        <ListPrice>
          <del>R$ {formatPrice(listPrice)}</del>
        </ListPrice>
      );
    }

    return <ListPrice />;
  }

  renderRating() {
    const rating = getIn(this.props, ['product', 'rating', 'average'], 0);

    return (
      <Rating>
        <Stars size={20} rating={rating} />
      </Rating>
    );
  }

  renderPreSale() {
    const isPreSale = getIn(this.props, ['product', 'isPreSale'], false);

    if (!isPreSale) {
      return null;
    }

    return <PreSale>Pré-Venda</PreSale>;
  }

  renderName() {
    const name = getIn(this.props, ['product', 'name'], '');
    const zionSlideShow = getIn(this.props, ['publication', 'zionSlideShow']);

    if (!name) {
      return null;
    }

    return !zionSlideShow ? (
      <Name numberOfLines={2}>{name}</Name>
    ) : (
      <NameSlideShow numberOfLines={2}>{name}</NameSlideShow>
    );
  }

  renderOutOfStockDetails() {
    const { publication } = this.props;
    const orientation = getIn(publication, ['orientation'], 'column');
    return (
      <Details orientation={orientation}>
        {this.renderName()}
        <OutOfStock>
          <Package width="60" height="25" fill="#2f2f2f" />
          <OutOfStockText>Infelizmente não temos esse produto em estoque</OutOfStockText>
        </OutOfStock>
      </Details>
    );
  }

  renderDetails() {
    const offer = getIn(this.props, ['product', 'offers', 'result', 0], null);
    const { publication } = this.props;
    const zionSlideShow = getIn(publication, ['zionSlideShow']);
    const orientation = getIn(publication, ['orientation'], 'column');

    if (!offer) {
      return this.renderOutOfStockDetails();
    }

    return !zionSlideShow ? (
      <Details orientation={orientation}>
        {this.renderName()}
        {this.renderRating()}
        <DetailsContainer>
          {this.renderListPrice()}
          <WrapperSalesPrice>{this.renderSalesPrice()}</WrapperSalesPrice>
          {this.renderInstallment()}
          {this.renderSponsored()}
        </DetailsContainer>
      </Details>
    ) : (
      <DetailsSlideShow orientation={orientation}>
        {this.renderName()}
        {this.renderRating()}
        <DetailsContainer>
          {this.renderListPrice()}
          <WrapperSalesPrice>{this.renderSalesPrice()}</WrapperSalesPrice>
          {this.renderInstallment()}
          {this.renderSponsored()}
        </DetailsContainer>
      </DetailsSlideShow>
    );
  }

  renderLoadingCard() {
    const { publication } = this.props;
    const zionSlideShow = getIn(publication, ['zionSlideShow']);

    const orientation = getIn(publication, ['orientation'], 'column');
    const type = getIn(publication, ['type'], 'carousel');

    return !zionSlideShow ? (
      <ProductWrapper orientation={orientation} type={type}>
        <Card orientation={orientation} placeholder={true}>
          <LoadingEffect />
          <LoadingCardImage />
          <Details orientation={orientation}>
            <Placeholder width="100%" />
            <Placeholder width="40%" />
            <Placeholder width="40%" />
            <Placeholder width="80%" />
            <Placeholder width="80%" height="21px" margin="10px 0px" />
            <Placeholder width="80%" />
          </Details>
        </Card>
      </ProductWrapper>
    ) : (
      <ProductWrapperZionSlideShow>
        <NavCardSlideShow>
          <LoadingEffect />
          <LoadingCardImageSlideShow />
          <DetailsSlideShow>
            <Placeholder width="100%" />
            <Placeholder width="40%" />
            <Placeholder width="40%" />
            <Placeholder width="80%" />
            <Placeholder width="80%" height="21px" margin="10px 0px" />
            <Placeholder width="80%" />
          </DetailsSlideShow>
        </NavCardSlideShow>
      </ProductWrapperZionSlideShow>
    );
  }
  renderFavorite() {
    return (
      <Favorite>
        <Heart width="18" height="15" />
      </Favorite>
    );
  }

  renderDiscountBadge() {
    const offer = getIn(this.props, ['product', 'offers', 'result', 0]) || {};

    const minQuantityRate = getIn(offer, ['bestPaymentOption', 'minInstallment', '0', 'discount', 'rate']);

    if (minQuantityRate) {
      return (
        <DiscountBadge>
          <WrapperDownArrow>
            <ArrowDiscount width="10" height="10" fill="#fff" />
          </WrapperDownArrow>
          <DiscountRate>{minQuantityRate >= 5 ? `${minQuantityRate}%` : 'baixou'}</DiscountRate>
        </DiscountBadge>
      );
    }

    const { rate: paymentOptionRate } = getIn(offer, ['bestPaymentOption', 'discount'], {});

    if (paymentOptionRate) {
      return (
        <DiscountBadge>
          <WrapperDownArrow>
            <ArrowDiscount width="10" height="10" fill="#fff" />
          </WrapperDownArrow>
          <DiscountRate>{paymentOptionRate >= 5 ? `${paymentOptionRate}%` : 'baixou'}</DiscountRate>
        </DiscountBadge>
      );
    }

    const { rate: offerDiscountRate } = getIn(offer, ['discount'], {});

    if (offerDiscountRate) {
      return (
        <DiscountBadge>
          <WrapperDownArrow>
            <ArrowDiscount width="10" height="10" fill="#fff" />
          </WrapperDownArrow>
          <DiscountRate>{offerDiscountRate >= 5 ? `${offerDiscountRate}%` : 'baixou'}</DiscountRate>
        </DiscountBadge>
      );
    }

    return null;
  }

  renderWrapperOutOfStock() {
    const offer = getIn(this.props, ['product', 'offers', 'result', 0], null);

    if (offer) {
      return null;
    }

    return <WrapperOutOfStock />;
  }

  renderNoImage() {
    return (
      <NoImageWrapper>
        <NoImage width="51" height="44" fill="#c9c9c9" />
        <NoImageText>Imagem indisponível</NoImageText>
      </NoImageWrapper>
    );
  }

  renderError() {
    const { publication } = this.props;
    const orientation = getIn(publication, ['orientation'], 'column');
    return (
      <ProductWrapper orientation={orientation}>
        {this.renderNoImage()}
        <Details orientation={orientation}>
          <Name>Não foi possível carregar esse produto</Name>
        </Details>
      </ProductWrapper>
    );
  }

  getPfmParams() {
    const { index, position, match, publication, pageType, qsKey } = this.props;

    const params = {
      chave: qsKey,
      pfm_carac: publication.title,
      pfm_page: pageType,
      pfm_pos: position,
      pfm_type: publication.rule ? 'vit_recommendation' : 'vit_spacey',
    };

    if (index > 0 || match.params.term === 'busca') {
      params.pfm_index = index;
    }

    return stringify(params);
  }

  addOfferIdToQueryString(product) {
    const offerId = getIn(product, ['offers', 'result', 0, 'offerId']);
    return offerId ? `&offerId=${offerId}` : '';
  }
  addQueryString(queryString) {
    return queryString ? `&${queryString}` : '';
  }
  getLink() {
    const { href, queryString, product } = this.props;

    const productId = getIn(product, ['id'], '');
    const productSlug = getIn(product, ['slug'], '');
    const pathname = getProductPathname({ id: productId, slug: productSlug });
    const pfmParams = this.getPfmParams();
    const offerIdParam = this.addOfferIdToQueryString(product);
    const queryParams = this.addQueryString(queryString);

    const link = pathname.concat(pfmParams, offerIdParam, queryParams);

    if (href) {
      return href;
    }

    return `${link}`;
  }

  renderEventStamp() {
    const stampName = getIn(this.props, ['product', 'offers', 'result', 0, 'stamps', 'events', 0]);

    if (!stampName) {
      return undefined;
    }

    const url = `https://images-americanas.b2w.io/selob2c/americanas/${stampName}.png`;

    return (
      <Wrapper>
        <img src={url} />
      </Wrapper>
    );
  }

  render() {
    const product = getIn(this.props, ['product'], {});
    const { loading, error, called, publication, sources } = this.props;
    const zionSlideShow = getIn(publication, ['zionSlideShow']);

    const orientation = getIn(publication, ['orientation'], 'column');
    const type = getIn(publication, ['type'], 'carousel');

    const defaultSources = {
      mobile: {
        src: getIn(product, ['images', 0, 'small']) || getIn(product, ['images', 0, 'large']),
        ratio: '1:1',
      },
    };

    const imageSources = sources ? sources : defaultSources;

    if (error && error.message.indexOf('Not Found') > -1) {
      return null;
    }

    if (error && has404OnPath(error.graphQLErrors, ['product'])) {
      return null;
    }

    if (error && hasCriticalErrors(error.graphQLErrors, ['product'])) {
      return this.renderError();
    }

    if (loading || called === false) {
      return this.renderLoadingCard();
    }

    return !zionSlideShow ? (
      <ProductWrapper orientation={orientation} type={type}>
        <NavGrid orientation={orientation} to={this.getLink()}>
          <CardImage>
            {this.renderEventStamp()}
            {this.renderWrapperOutOfStock()}
            {this.renderDiscountBadge()}
            <GradientUI />
            <LazyPicture sources={imageSources} alt={product.name} />
          </CardImage>
          {this.renderDetails()}
        </NavGrid>
      </ProductWrapper>
    ) : (
      <ProductWrapperZionSlideShow>
        <NavCardSlideShow to={this.getLink()}>
          <CardImageSlideShow>
            {this.renderEventStamp()}
            {this.renderWrapperOutOfStock()}
            {this.renderDiscountBadge()}
            <GradientUI />
            <LazyPicture sources={imageSources} alt={product.name} />
          </CardImageSlideShow>
          {this.renderDetails()}
        </NavCardSlideShow>
      </ProductWrapperZionSlideShow>
    );
  }
}

ProductCard.propTypes = {
  product: PropTypes.object,
  error: PropTypes.object,
  loading: PropTypes.bool,
  id: PropTypes.string,
  queryString: PropTypes.string,
  href: PropTypes.string,
  called: PropTypes.bool,
  trackingUrl: PropTypes.string,
  rule: PropTypes.string,
  position: PropTypes.string,
  publication: PropTypes.object,
  zionSlideShow: PropTypes.bool,
};

ProductCard.defaultProps = {
  loading: true,
  position: '',
  publication: {},
};

const NavGrid = styled(Nav)`
  ${({ orientation }) => css`
    ${orientation === 'row' &&
    css`
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: nowrap !important;
      align-items: center;
      padding: 0 10px;
    `}
  `}
`;

const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 5;

  img {
    max-width: 100%;
  }
`;

const Card = styled.div`
  ${({ orientation, placeholder }) => css`
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-shrink: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
    background: rgb(255, 255, 255);

    ${orientation === 'row' &&
    css`
      flex-direction: row;
      width: 100%;
    `}

    ${placeholder &&
    css`
      min-height: 251.5px;
    `}
  `}
`;
const WrapperSalesPrice = styled.div`
  display: flex;
  align-items: center;
`;
const LoadingEffect = styled.div`
  position: absolute;
  top: 0;
  left: 40px;
  width: 42px;
  min-height: 100%;
  background: #eee;
  box-shadow: inset 0 0 18px #fff;
  opacity: 0.5;
  z-index: 1;
`;

const LoadingCardImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #f7f7f7;
  min-height: 200px;
  padding: 3px;
  border-radius: 6px 6px 0 0;
  flex: 1 1 50%;
`;

const Placeholder = styled.div`
  ${({ orientation }) => css`
    background: #f7f7f7;
    margin-bottom: 4px;
    margin: ${({ margin }) => `${margin}`};
    height: ${({ height }) => `${!height ? '10px' : height}`};
    max-width: ${({ width }) => `${width}`};

    ${orientation === 'row' &&
    css`
      flex-direction: row;
      height: auto;
      display: flex;
    `}
  `}
`;
const ProductWrapper = styled(Card)`
  ${({ orientation, type }) => css`
    ${
      orientation === 'column' &&
      css`
        width: 232px;
        height: 368px;
        margin: 2px;
        box-shadow: none;
        background: transparent;
      `
    }
    ${
      orientation === 'row' &&
      css`
        background-color: transparent;
        box-shadow: none;
        margin: 0 auto;
        width: 100%;
        height: 368px;
        box-shadow: none;
        background: transparent;
      `
    }

    ${
      type === 'grid' &&
      css`
        width: 100%;
        height: 374px;
      `
    }

    a {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 100%;
    }
  `}
`;

const GradientUI = styled.div`
  position: absolute;
  z-index: 1;
  border-radius: 6px 6px 0 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.05;
`;

const CardImage = styled.div`
  position: relative;
  width: 100%;
  padding: 17px 40px;
`;

const NoImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  height: 162px;
  padding: 3px;
  border-radius: 6px;
`;

const NoImageText = styled(Text)`
  margin-top: 15px;
  color: #9a9a9a;
  font-weight: 500;
  font-size: 12px;
`;

const Details = styled.div`
  ${({ orientation }) => css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 10px;
    min-width: 100%;
    height: 50%;
    justify-content: space-between;
    margin-bottom: auto;

    ${orientation === 'row' &&
    css`
      flex: 0 0 50%;
      min-width: auto;
      margin: auto 0;
    `}
  `}
`;

const DetailsContainer = styled.div`
  margin-bottom: auto;
`;

const PreSale = styled(Text)`
  color: #6823cd;
  font-size: 8px;
  font-weight: bold;
  padding: 0 11px;
  border: solid #6823cd 2px;
  border-radius: 3px;
  width: fit-content;
`;

const Name = styled(Text)`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  margin-bottom: 10px;
  color: #666;
  min-height: 35px;
`;

const Installment = styled(Text)`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  display: block;
  color: #7b7b7b;
  margin-bottom: 7px;
`;

const Price = styled.div`
  font-weight: bold;
  line-height: 30px;
  font-size: 18px;
  margin-right: 5px;
`;

const Sponsored = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px 0px;
`;

const SponsoredText = styled(Text)`
  font-size: 10px;
  color: #000;
`;

const ListPrice = styled.div`
  margin-top: 5px;
  font-size: 11px;
  color: #646464;
  min-height: 13px;
`;

const WrapperOutOfStock = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  margin-left: -36px;
  z-index: 1;
`;

const DiscountBadge = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  display: flex;
  background: #40cd28;
  min-height: 18px;
  border-radius: 5px;
  padding: 0px 5px;
`;

const WrapperDownArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
`;

const DiscountRate = styled(Text)`
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  align-self: center;
`;

const Favorite = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 250px;
  background: #fff;
  opacity: 0.92;
`;

const OutOfStock = styled.div`
  display: flex;
  align-items: center;
  background: #eee;
  height: 60px;
  padding: 5px;
  border-radius: 14px;
  color: #333;
  font-size: 12px;
`;

const OutOfStockText = styled(Text)`
  padding-left: 5px;
  font-size: 11px;
  color: #000;
  text-align: left;
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex: 1 0 auto;
  align-items: baseline;
  max-height: 40px;
`;

const ProductWrapperZionSlideShow = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 1250px;
  box-shadow: none;
`;

const NavCardSlideShow = styled(Nav)`
  background: rgb(255, 255, 255);
  display: flex;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 251.5px;
  width: 600px;
  gap: 15px;
`;

const LoadingCardImageSlideShow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #f7f7f7;
  min-height: 200px;
`;

const CardImageSlideShow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 214px;
  min-height: 214px;
`;

const DetailsSlideShow = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

const NameSlideShow = styled(Text)`
  font-size: ${({ fontSize }) => fontSize || '24px'};
  margin-bottom: 10px;
  color: #666;
  min-height: 52px;
`;

const PriceSlideShow = styled.div`
  font-weight: bold;
  line-height: 30px;
  font-size: 20px;
  margin-right: 5px;
`;

const InstallmentSlideShow = styled(Text)`
  font-size: ${({ fontSize }) => fontSize || '12px'};
  display: block;
  color: #7b7b7b;
  margin-bottom: 7px;
`;

export default compose(withMetrics, withRouter, withPageType, withProduct)(ProductCard);
